<template>
  <div>
    <transferenceManagebar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้ายสายบริหาร"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12">
              <v-simple-table>
                <tbody>
                  <tr>
                    <td class="text-center font-weight-bold">
                      <v-chip outlined color="info">
                        <h1>
                          <v-icon>mdi-account-multiple</v-icon> จำนวน
                          {{ countAll }} ราย
                        </h1></v-chip
                      >
                    </td>
                    <td class="text-center font-weight-bold">
                      <v-chip outlined color="primary">
                        <h1>
                          <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                          บันทึกแล้ว {{ countSaves }} ราย
                        </h1></v-chip
                      >
                    </td>
                    <td class="text-center font-weight-bold">
                      <v-chip outlined color="warning">
                        <h1>
                          <v-icon>mdi-timer-sand</v-icon> ดำเนินการ
                          {{ countTem }} ราย
                        </h1></v-chip
                      >
                    </td>
                  </tr>

                  <tr>
                    <td class="text-center font-weight-bold">
                      <v-chip outlined color="info">
                        <h1>ปกติ {{ countNormal }} ราย</h1></v-chip
                      >
                    </td>
                    <td class="text-center font-weight-bold">
                      <v-chip outlined color="primary">
                        <h1>พัฒนาคุณภาพ {{ countquality }} ราย</h1></v-chip
                      >
                    </td>
                    <td class="text-center font-weight-bold">
                      <v-chip outlined color="warning">
                        <h1>กรณีพิเศษ {{ countsp }} ราย</h1></v-chip
                      >
                    </td>
                  </tr>

                  <tr>
                    <td class="text-center">
                      <v-btn
                        rounded
                        color="info"
                        :href="
                          '#/admin/print_report_movement_manage_online/' +
                            periods.period_times +
                            '/' +
                            period_years
                        "
                        target="_blank"
                        ><v-icon>mdi-printer</v-icon>พิมพ์รายงาน แบบที่ 1
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-btn
                        rounded
                        color="info"
                        :href="
                          '#/admin/print_reportAssesmentManageAllDr/' +
                            periods.period_times +
                            '/' +
                            period_years +
                            '/' +
                            'dr'
                        "
                        target="_blank"
                      >
                        <v-icon>mdi-printer</v-icon> พิมพ์แบบบันทึกผล ผอ.
                        ทั้งหมด</v-btn
                      >
                    </td>
                    <td class="text-center">
                      <v-btn
                        rounded
                        color="info"
                        :href="
                          '#/admin/print_reportAssesmentManageAllDrDev/' +
                            periods.period_times +
                            '/' +
                            period_years +
                            '/' +
                            'drdev'
                        "
                        target="_blank"
                        ><v-icon>mdi-printer</v-icon> แบบบันทึกผล ผอ.
                        เพื่อการพัฒนา
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <v-btn
                        rounded
                        color="info"
                        :href="
                          '#/admin/print_report_movement_manage_online2/' +
                            periods.period_times +
                            '/' +
                            period_years
                        "
                        target="_blank"
                      >
                        <v-icon>mdi-printer</v-icon> พิมพ์รายงาน แบบที่ 2</v-btn
                      >
                    </td>
                    <td class="text-center">
                      <v-btn
                        rounded
                        color="info"
                        :href="
                          '#/admin/print_reportAssesmentManageAllSeDr/' +
                            periods.period_times +
                            '/' +
                            period_years +
                            '/' +
                            'sedr'
                        "
                        target="_blank"
                        ><v-icon>mdi-printer</v-icon> พิมพ์แบบบันทึกผล รองผอ.
                        ทั้งหมด</v-btn
                      >
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>

        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-radio-group v-model="position" @change="positionSearch()" row>
          <v-radio
            label="ผู้อำนวยการวิทยาลัย"
            value="ผู้อำนวยการวิทยาลัย"
          ></v-radio>
          <v-radio
            label="รองผู้อำนวยการวิทยาลัย"
            value="รองผู้อำนวยการวิทยาลัย"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="transference_manages"
          :search="search"
          item-key="manage_id_ref"
          disable-pagination
        >
          <template v-slot:item.actionCancel="{ item }">
            <v-btn
              fab
              small
              color="error"
              dark
              @click="CancelTransference(item.manage_id_tfp)"
              ><v-icon>mdi-cancel</v-icon></v-btn
            >
          </template>

          <template v-slot:item.action="{ item }">
            <v-btn icon color="red" @click="DelTransference(item.manage_id_tfp)"
              ><v-icon>mdi-delete-circle</v-icon></v-btn
            >
          </template>

          <template v-slot:item.manage_status_save="{ item }">
            <v-chip
              @click="CancelSave(item.manage_id_tfp)"
              color="green"
              dark
              v-if="item.manage_status_save === 'Save'"
            >
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon> บันทึก
            </v-chip>
            <v-chip v-else color="warning">
              <v-icon>mdi-timer-sand</v-icon> ชั่วคราว
            </v-chip>
          </template>

          <template v-slot:item.manage_case_move="{ item }">
            <div>
              <v-chip v-if="item.manage_case_move === 'normal'" color="primary"
                >ปกติ</v-chip
              >
              <v-chip v-if="item.manage_case_move === 'quality'" color="info"
                >คุณภาพ</v-chip
              >
              <v-chip v-if="item.manage_case_move === 'special'" color="warning"
                >กรณีพิเศษ</v-chip
              >
            </div>
          </template>

          <template v-slot:item.college_name="{ item }">
            <v-chip color="">
              <span style="font-size:16px;"> {{ item.college_name }}</span>
            </v-chip>
          </template>

          <template v-slot:item.manage_time_s="{ item }">
            {{ item.manage_time_s + "/" + item.manage_year_s }}
          </template>

          <template v-slot:item.succ_college="{ item }">
            <v-chip :color="getColor(item.succ_college)" dark>
              <span style="font-size:16px;"> {{ item.succ_college }}</span>
            </v-chip>
          </template>

          <template v-slot:item.college_name_suss="{ item }">
            <v-chip v-if="item.college_name_suss === ''"> </v-chip>
            <v-chip
              v-else-if="item.college_name_suss !== ''"
              color="green"
              dark
            >
              <span style="font-size:16px;"> {{ item.college_name_suss }}</span>
            </v-chip>
          </template>

          <template v-slot:item.manage_comment_supervisor="{ item }">
            <v-chip
              class="ma-1"
              v-if="item.manage_comment_supervisor"
              color="green"
              @click.stop="
                manage_comment_supervisorPosition(item.manage_id_ref)
              "
              dark
            >
              <h3>
                {{ item.manage_comment_supervisor }}
              </h3>
            </v-chip>

            <v-icon
              large
              v-else
              color="info"
              @click.stop="
                manage_comment_supervisorPosition(item.manage_id_ref)
              "
              >mdi-comment-processing</v-icon
            >
          </template>

          <template v-slot:item.manage_status_document="{ item }">
            <v-chip
              v-if="item.manage_status_document === 'complete'"
              color="green"
              @click.stop="comment_idPosition(item.manage_id_ref)"
              dark
              >ถูกต้องสมบูรณ์</v-chip
            >
            <v-chip
              v-else-if="item.manage_status_document === 'incomplete'"
              color="warning"
              dark
              @click.stop="comment_idPosition(item.manage_id_ref)"
              >ไม่สมบูรณ์</v-chip
            >
            <v-chip
              v-else-if="item.manage_status_document === 'do_not'"
              color="red"
              dark
              @click.stop="comment_idPosition(item.manage_id_ref)"
              >ไม่จัดส่ง</v-chip
            >
          </template>

          <template v-slot:item.printAss="{ item }">
            <v-btn
              text
              :href="
                '#/admin/print_reportAssesmentManage/' + item.manage_id_ref
              "
              target="_blank"
              rounded
              color="info"
            >
              <v-icon large>mdi-printer</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-chip v-if="item.status_select === 'agree'" color="warning" dark>
              <span style="font-size:16px;">ปกติ (2)</span>
            </v-chip>
            <v-chip
              v-else-if="item.status_select === 'sw_normal'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">สับเปลี่ยน (3)</span>
            </v-chip>
            <v-chip
              v-else-if="item.status_select === 'sw_agree'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">แลกเปลี่ยน (4)</span>
            </v-chip>
            <v-icon
              v-else-if="item.succ_college >= 1"
              color="red"
              large
              @click.stop="deletePosition(item.id_ref)"
              >mdi-delete-circle</v-icon
            >
            <v-icon
              v-else
              color="yellow"
              large
              @click.stop="select_idPosition(item.manage_id_ref)"
              >mdi-credit-card-plus</v-icon
            >
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model canceldialog -->
      <v-layout row justify-center>
        <v-dialog v-model="canceldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ยกเลิกรายการ"
              class="px-5 py-3 text_google"
            >
              <div class="text-right"></div>
            </base-material-card>
            <v-card-text>
              <v-form ref="cancelform" lazy-validation>
                {{ conditons_transfer_successs.id_cts }}
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <p>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.id_ref }}
                      </p>

                      <div class="text--center">
                        รหัสบัตรประชาชน :
                        {{ transference_manages_id_ref.id_card }}
                        ชื่อ-นามสกุล :
                        {{ transference_manages_id_ref.title_s
                        }}{{ transference_manages_id_ref.frist_name }}
                        {{ transference_manages_id_ref.last_name }}

                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </div>
                    </v-flex>
                    <v-flex md6>
                      <p>
                        รหัสวิทยาลัยปลายทาง :
                        {{ man_powerss.college_code || "สับเปลี่ยนตำแหน่ง" }}
                      </p>
                      <div>
                        วิทยาลัย :
                        {{ man_powerss.college_name || "สับเปลี่ยนตำแหน่ง" }}
                      </div>
                      เลขที่ตำแหน่ง :
                      {{ man_powerss.id_position || "สับเปลี่ยนตำแหน่ง" }}
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="canceldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn large color="warning" @click.stop="cancelSubmit()" rounded>
                <v-icon dark>mdi-pencil</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model positiondialog -->
      <v-layout row justify-center>
        <v-dialog v-model="positiondialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เลือกสถานศึกษา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatepositionform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.manage_id_ref }}
                      </h4>
                      รหัสบัตรประชาชน :
                      {{ transference_manages_id_ref.id_card }}
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ transference_manages_id_ref.title_s
                        }}{{ transference_manages_id_ref.frist_name }}
                        {{ transference_manages_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_code }}
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </h4>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_manages_id_ref.manage_time_s }} ปี :
                        {{ this.transference_manages_id_ref.manage_year_s }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="header_trans"
                        :items="transference_manage_locations"
                        class="elevation-1"
                      >
                      </v-data-table>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-card elevation="2" class="pa-2">
                        <h2>
                          เหตุผลในการย้าย :
                        </h2>
                        <h3>
                          {{ transference_manages_id_ref.manage_reason_detail }}
                        </h3>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-col cols="12" md="12">
                    <v-card elevation="2" class="pa-2">
                      <v-flex xs12>
                        <v-autocomplete
                          prepend-icon="mdi-account-details"
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_code"
                          v-model="updatepositions.college_code"
                          label="วิทยาลัย"
                          @change="man_powerQuery()"
                          required
                          :rules="[v => !!v || '']"
                          outlined
                        >
                        </v-autocomplete>
                      </v-flex>
                      <v-flex md12>
                        <v-autocomplete
                          prepend-icon="mdi-account-details"
                          :items="man_powers"
                          item-text="college_position_case"
                          item-value="id_position"
                          label="เลขที่ตำแหน่ง"
                          v-model="updatepositions.id_position"
                          outlined
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-card>
                  </v-col>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="positiondialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatepositionSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model commentDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="commentDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เพิ่มความคิดเห็นผู้อำนวยการ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.manage_id_ref }}
                        {{ transference_manages_id_ref.id_tfp }}
                      </h4>
                      รหัสบัตรประชาชน :
                      {{ transference_manages_id_ref.id_card }}
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ transference_manages_id_ref.title_s
                        }}{{ transference_manages_id_ref.frist_name }}
                        {{ transference_manages_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_code }}
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </h4>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_manages_id_ref.manage_time_s }} ปี :
                        {{ this.transference_manages_id_ref.manage_year_s }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h2>ความคิดเห็น</h2>
                        <h2>
                          <v-radio-group
                            v-model="transference_manages_id_ref.comment_dr_c"
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="approp">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text"
                                    >เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="inapprop">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="suspend">
                              <template v-slot:label>
                                <div>
                                  <strong class="red--text">ระงับย้าย</strong>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </h2>
                      </v-alert>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h2>สถานะการยืนเสนอเอกสารประกอบการพิจารณา</h2>
                        <h2>
                          <v-radio-group
                            v-model="
                              transference_manages_id_ref.manage_status_document
                            "
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="complete">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text"
                                    >ถูกต้องสมบูรณ์</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="incomplete">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่ถูกต้องไม่สมบูรณ์</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="do_not">
                              <template v-slot:label>
                                <div>
                                  <strong class="red--text"
                                    >ไม่จัดส่งเอกสาร</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </h2>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        outlined
                        label=" ความเห็นของผู้บังคับบัญชา :"
                        v-model="transference_manages_id_ref.detail_comment"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="commentDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatecommentSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model comment_stb_Drdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="comment_stb_Drdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เพิ่มความคิดเห็นผู้อำนวยการสถาบันการอาชีวศึกษา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecomment_stbform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.manage_id_ref }}
                        {{ transference_manages_id_ref.id_tfp }}
                      </h4>
                      รหัสบัตรประชาชน :
                      {{ transference_manages_id_ref.id_card }}
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ transference_manages_id_ref.title_s
                        }}{{ transference_manages_id_ref.frist_name }}
                        {{ transference_manages_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_code }}
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </h4>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_manages_id_ref.manage_time_s }} ปี :
                        {{ this.transference_manages_id_ref.manage_year_s }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h2>สถานะการยืนเสนอเอกสารประกอบการพิจารณา</h2>
                        <h2>
                          <v-radio-group
                            v-model="
                              transference_manages_id_ref.manage_status_document
                            "
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="complete">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text"
                                    >ถูกต้องสมบูรณ์</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="incomplete">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่ถูกต้องไม่สมบูรณ์</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="do_not">
                              <template v-slot:label>
                                <div>
                                  <strong class="red--text"
                                    >ไม่จัดส่งเอกสาร</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </h2>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h2>ความคิดเห็น</h2>
                        <v-text-field
                          outlined
                          label=" ความเห็นของผู้บังคับบัญชา :"
                          v-model="
                            transference_manages_id_ref.manage_comment_supervisor
                          "
                        ></v-text-field>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" md="12"> </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="comment_stb_Drdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatecomment_stbSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import transferenceManagebar from "../../components/admin/transferenceManagebar.vue";
export default {
  components: { transferenceManagebar },
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      position: "",
      branch_s: [],
      manage_id_ref: [],
      valid: true,
      canceldialog: false,
      positiondialog: false,
      clear_dataDialog: false,
      commentDrdialog: false,
      comment_stb_Drdialog: false,
      times_select: "1",
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      position: "",
      manage_time_s: [1, 2],
      manage_year_s: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_manages: [],
      edittransference_manage: {},
      search: "",
      pagination: {},

      singleSelect: false,
      selected: [],

      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "ยกเลิกบันทึก", align: "left", value: "actionCancel" },

        { text: "สถานะ", align: "center", value: "manage_status_save" },
        { text: "กรณี", align: "center", value: "manage_case_move" },
        { text: "Ref", align: "center", value: "manage_id_ref" },
        { text: "คำนำหน้า", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "นามสกุล", align: "left", value: "last_name" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่งเดิม", align: "left", value: "id_position" },
        { text: "ครั้งที่/ปี", align: "center", value: "manage_time_s" },
        {
          text: "อายุงาน ณ ปัจจุบัน",
          align: "center",
          value: "manage_age_app_time"
        },
        {
          text: "ความคิดเห็นผู้บังคับฯ",
          align: "center",
          value: "manage_comment_supervisor"
        },
        { text: "เอกสาร", align: "center", value: "manage_status_document" },
        { text: "วันที่ทำรายการ", align: "center", value: "manage_date_time" },
        { text: "ผลการพิจารณา", align: "center", value: "actions" },
        { text: "แห่งใหม่", align: "center", value: "succ_college" },
        { text: "วิทยาลัยแห่งใหม่", align: "left", value: "college_name_suss" },
        { text: "เลขที่ตำแหน่งใหม่", align: "left", value: "id_position_new" },
        { text: "ลบ", align: "left", value: "action" }
      ],

      header_trans: [
        {
          text: "ลำดับ",
          align: "center",
          value: "manage_location_sequence_n"
        },
        {
          text: "รหัสอ้างอิง",
          align: "center",
          value: "manage_location_id_ref"
        },
        { text: "วิทยาลัย", align: "left", value: "college_name" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_managestatus: [],
      transference_manages_id_ref: [],
      conditons_transfer_successs: [],
      updatepositions_condition: {},
      man_power_cancel: {},
      addreturn_man_power: {},
      transference_manage_locations: [],
      conditons_transfer_success_del: [],
      man_powers: [],
      userstatus: {},
      updatepositions: {},
      man_powerss: [],
      id_return_man_powers: [],
      colleges: [],
      data_select: [],
      provinces: [],
      regions: [],
      periods: [],
      period_enable_process: "1",
      updatecomment: {},
      transference_manages_id_tfp: [],
      countSaves: [],
      countTem: [],
      countAll: [],
      countNormal: [],
      countquality: [],
      countsp: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    let result;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
    await this.periodQuery();
    await this.transference_manageQueryAll();
  },
  methods: {
    async CancelSave(manage_id_tfp) {
      let result = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_tfp: manage_id_tfp
      });
      this.transference_manages_id_tfp = result.data;

      let id_tfp =
        this.transference_manages_id_tfp.title_s +
        this.transference_manages_id_tfp.frist_name +
        " " +
        this.transference_manages_id_tfp.last_name;
      Swal.fire({
        title: "ต้องการยกเลิกสถานะการบันทึกรายการนี้ ?",
        text: id_tfp,
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "ยืนยันลบ",
        showLoaderOnConfirm: true,
        preConfirm: async login => {
          if (login === "0612698358") {
            this.transference_manages_id_tfp.ApiKey = this.ApiKey;
            this.transference_manages_id_tfp.manage_status_save = "";
            let resultdel = await this.$http.post(
              "transference_manage.update.php",
              this.transference_manages_id_tfp
            );
            if (resultdel.data.status == true) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการลบข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              await this.transference_manageQueryAll();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        }
      });
    },
    async CancelTransference(manage_id_tfp) {
      let result = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_tfp: manage_id_tfp
      });
      this.transference_manages_id_tfp = result.data;

      let id_tfp =
        this.transference_manages_id_tfp.title_s +
        this.transference_manages_id_tfp.frist_name +
        " " +
        this.transference_manages_id_tfp.last_name;
      Swal.fire({
        title: "ต้องการยกเลิกรายการบันทึกนี้ ?",
        text: id_tfp,
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "ยืนยันยกเลิกบันทึก",
        showLoaderOnConfirm: true,
        preConfirm: async login => {
          if (login === "0612698358") {
            this.transference_manages_id_tfp.ApiKey = this.ApiKey;
            this.transference_manages_id_tfp.manage_status_save = 17;
            let resultcancel = await this.$http.post(
              "transference_manage.update.php",
              this.transference_manages_id_tfp
            );
            
            if (resultcancel.data.status == true) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการลบข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              await this.transference_manageQueryAll();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        }
      });

    },

    async DelTransference(manage_id_tfp) {
      let result = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_tfp: manage_id_tfp
      });
      this.transference_manages_id_tfp = result.data;

      let id_tfp =
        this.transference_manages_id_tfp.title_s +
        this.transference_manages_id_tfp.frist_name +
        " " +
        this.transference_manages_id_tfp.last_name;
      Swal.fire({
        title: "ต้องการลบรายการนี้ ?",
        text: id_tfp,
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "ยืนยันลบ",
        showLoaderOnConfirm: true,
        preConfirm: async login => {
          if (login === "0612698358") {
            this.transference_manages_id_tfp.ApiKey = this.ApiKey;
            let resultdel = await this.$http.post(
              "transference_manage.delete.php",
              this.transference_manages_id_tfp
            );
            if (resultdel.data.status == true) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการลบข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              await this.transference_manageQueryAll();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        }
      });
    },

    async positionSearch() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.periods.period_times,
          manage_year_s: this.periods.period_year,
          position_name: this.position
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_type: "manage"
      });
      this.periods = result_period.data;
    },

    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.periods.period_times,
          manage_year_s: this.years_select.period_year
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;
    },

    async showSuccessPosition() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.times_select,
          manage_year_s: this.years_select,
          success_s: "ok"
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;
    },

    async select_idPosition(manage_id_ref) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });

      let result = await this.$http.post("transference_manage_location.php", {
        ApiKey: this.ApiKey,
        manage_location_id_ref: manage_id_ref
      });
      this.transference_manage_locations = result.data;

      this.transference_manages_id_ref = result_con.data;
      this.positiondialog = true;
    },

    async comment_idPosition(manage_id_ref) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });
      this.transference_manages_id_ref = result_con.data;
      this.commentDrdialog = true;
    },

    async manage_comment_supervisorPosition(manage_id_ref) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });
      this.transference_manages_id_ref = result_con.data;
      this.comment_stb_Drdialog = true;
    },

    async transference_manageQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          transferce_manage_main: "Check",
          manage_time_s: this.periods.period_times,
          manage_year_s: this.periods.period_year
        })
        .finally(() => (this.loading = false));
      this.transference_manages = result.data;
      let data = this.transference_manages;
      let countSave = 0;
      let countTem = 0;
      let countAll = 0;
      let countNormal = 0;
      let countquality = 0;
      let countsp = 0;
      data.forEach(value => {
        if (value.manage_status_save == "Save") {
          countSave += 1;
        } else {
          countTem += 1;
        }

        if (value.manage_case_move == "normal") {
          countNormal += 1;
        } else if (value.manage_case_move == "quality") {
          countquality += 1;
        } else if (value.manage_case_move == "special") {
          countsp += 1;
        }
        countAll += 1;
      });
      this.countSaves = countSave;
      this.countTem = countTem;
      this.countAll = countAll;

      this.countNormal = countNormal;
      this.countquality = countquality;
      this.countsp = countsp;
    },

    async man_powerQuery() {
      let man_power_result;
      man_power_result = await this.$http.post("man_power_process.php", {
        ApiKey: this.ApiKey,
        college_code: this.updatepositions.college_code
      });
      this.man_powers = man_power_result.data;
    },

    async updatecommentSubmit() {
      if (this.$refs.updatecommentform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.id_tfp = this.transference_manages_id_ref.id_tfp;
        this.updatecomment.comment_dr_c = this.transference_manages_id_ref.comment_dr_c;
        this.updatecomment.id_branch = this.transference_manages_id_ref.id_branch_tan;
        this.updatecomment.detail_comment = this.transference_manages_id_ref.detail_comment;
        this.updatecomment.manage_status_document = this.transference_manages_id_ref.manage_status_document;

        let result = await this.$http.post(
          "transference_manage.update.php",
          this.updatecomment
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manageQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.commentDrdialog = false;
      }
    },

    async updatecomment_stbSubmit() {
      if (this.$refs.updatecomment_stbform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.manage_id_ref = this.transference_manages_id_ref.manage_id_ref;
        this.updatecomment.manage_comment_supervisor = this.transference_manages_id_ref.manage_comment_supervisor;
        this.updatecomment.manage_status_document = this.transference_manages_id_ref.manage_status_document;
        
        let result = await this.$http.post(
          "transference_manage.update.php",
          this.updatecomment
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manageQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.comment_stb_Drdialog = false;
      }
    },

    /// updatepositionSubmit
    async updatepositionSubmit() {
      if (this.$refs.updatepositionform.validate()) {
        this.updatepositions.ApiKey = this.ApiKey;
        this.updatepositions.time_s = this.transference_manages_id_ref.manage_time_s;
        this.updatepositions.year_s = this.transference_manages_id_ref.manage_year_s;
        this.updatepositions.college_code_old = this.transference_manages_id_ref.college_code;
        this.updatepositions.id_postion_old = this.transference_manages_id_ref.id_position;
        this.updatepositions.id_card = this.transference_manages_id_ref.id_card;
        this.updatepositions.id_ref = this.transference_manages_id_ref.manage_id_ref;
        this.updatepositions.name_position = this.position;
        this.updatepositions.status_select = "demand";

        this.updatepositions_condition.ApiKey = this.ApiKey;
        this.updatepositions_condition.id_position = this.updatepositions.id_position;
        this.updatepositions_condition.status_booking = this.transference_manages_id_ref.id_card;

        this.addreturn_man_power.ApiKey = this.ApiKey;
        this.addreturn_man_power.college_code = this.transference_manages_id_ref.college_code;
        this.addreturn_man_power.id_position = this.transference_manages_id_ref.id_position;
        this.addreturn_man_power.position = this.position;
        this.addreturn_man_power.case_vacancy =
          "ย้ายรอบ-" +
          this.transference_manages_id_ref.manage_time_s +
          "/" +
          this.transference_manages_id_ref.manage_year_s;

        let result_man_return = await this.$http.post(
          "man_power.insert.php",
          this.addreturn_man_power
        );

        if (result_man_return.data.status == true) {
          let result_man = await this.$http.post(
            "man_power.update_process.php",
            this.updatepositions_condition
          );

          let result = await this.$http.post(
            "conditons_transfer_success.insert.php",
            this.updatepositions
          );

          if (result_man.data.status == true && result.data.status == true) {
            this.snackbar.icon = "mdi-font-awesome";
            this.snackbar.color = "success";
            this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
            this.snackbar.show = true;
            this.transference_manageQueryAll();
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.positiondialog = false;
      }
    },

    async deletePosition(id_ref) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });
      this.transference_manages_id_ref = result_con.data;

      let result_man = await this.$http.post("man_power.php", {
        ApiKey: this.ApiKey,
        id_card: this.transference_manages_id_ref.id_card
      });
      this.man_powerss = result_man.data;

      let result_cts = await this.$http.post("conditons_transfer_success.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });

      this.conditons_transfer_successs = result_cts.data;
      this.canceldialog = true;
    },

    async cancelSubmit() {
      if (this.$refs.cancelform.validate()) {
        this.man_powerss.ApiKey = this.ApiKey;
        this.man_powerss.status_booking = "";

        this.conditons_transfer_successs.ApiKey = this.ApiKey;

        this.man_power_cancel.ApiKey = this.ApiKey;
        this.man_power_cancel.id_position = this.conditons_transfer_successs.id_postion_old;

        let result_man = await this.$http.post(
          "man_power.update_process.php",
          this.man_powerss
        );

        let result_man_delete = await this.$http.post(
          "man_power.delete.php",
          this.man_power_cancel
        );

        let result_cts = await this.$http.post(
          "conditons_transfer_success.delete.php",
          this.conditons_transfer_successs
        );

        if (
          result_man.data.status == true &&
          result_cts.data.status == true &&
          result_man_delete.data.status == true
        ) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "ยกเลิกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.transference_manageQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ยกเลิกข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.canceldialog = false;
      }
    },

    getColor(calories) {
      /*  if (calories > 400) return 'red'
        else if (calories > 200) return 'orange'
        else return 'green'  */

      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    period_years() {
      let yyyy = this.periods.period_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  }
};
</script>
